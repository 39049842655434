<template>
  <custom-assessment :on-mount="loadOnMount">
    <!--  суть проверки в том, чтобы показывать выбор оценки только для эксперта,
    т.к. есть смежная роль "проверяющий" у которого по сути функционал в режиме ридонли !-->
    <template #prepend-chat>
      <div class="custom-assessment__title">Оценка</div>
      <div class="custom-assessment__form">
        <div
            class="form__group"
            v-for="(scale, index) in scales"
            :key="scale.uuid"
        >
          <app-select
              class="mt-select_form-select"
              :items="scale.grades.map((grade) => ({
                value: grade,
                label: grade,
              }))"
              :errors="v$?.scales?.$each?.$response?.$errors[index].value ?? []"
              :label="scale.name"
              hide-details
              item-text="label"
              item-value="value"
              v-model="scale.value"
          />
        </div>
        <div class="form__group">
          <mt-button
              class="form__btn custom-assessment__form__btn"
              @click="setMark()"
              :loading="setMarkRequestPending"
          >
            Сохранить
          </mt-button>
        </div>
      </div>
    </template>
  </custom-assessment>
</template>

<script>
import mtButton from '@/components/UI/mtButton/index.vue';
import CustomAssessment from '@/components/workspace/assessmentPlans/plans/plan/CustomAssessment/CustomAssessment.vue';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import AppSelect from "@/components/UI/AppSelect/AppSelect.vue";
import CustomAssessmentApi from "@/api/custom-assessment.api";
import useVuelidate from "@vuelidate/core";
import {helpers} from "@vuelidate/validators";
import {useValidations} from "@/plugins/vuelidate-wrapper";

export default {
  name: "expertCustomAssessment",
  components: {AppSelect, CustomAssessment, mtButton},
  data () {
    return {
      setMarkRequestPending: false,
    }
  },
  setup() {
    return {
      v$: useVuelidate({
        $lazy: true
      }),
    }
  },
  validations() {
    const { required } = useValidations();

    return {
      scales: {
        $each: helpers.forEach({
          value: {
            required
          }
        })
      }
    }
  },

  computed: {
    ...mapState('assessmentPlans/plan/task', ['mark', 'scales', 'sessionUuid']),
    ...mapGetters(['isExpert']),
    planUuid() {
      return this.$route.params.planUuid;
    },
    taskUuid() {
      return this.$route.params.taskUuid;
    },
    respondentUuid() {
      return this.$route.params.respondentUuid;
    }
  },
  methods: {
    ...mapActions('assessmentPlans/plan/task', ['getScales', 'getMark', 'getUserSession']),
    ...mapMutations('assessmentPlans/plan/task', ['updateStore']),
    ...mapActions('assessmentPlans/plan', ['setPlanProgress']),

    async setMark() {
      const isValid = await this.v$.$validate();
      if(!isValid) return

      this.setMarkRequestPending = true;

      const payload = this.scales.map((item) => {
        return {
          uuid: item.uuid,
          grade: item.value
        }
      });
      CustomAssessmentApi.setMark(
          this.planUuid, 
          this.taskUuid, 
          this.sessionUuid,
          payload, 
      )
          .then(() => {
            this.setPlanProgress({
              uuid: this.planUuid,
            });
            this.getMark({
              planUuid: this.planUuid,
              taskUuid: this.taskUuid,
              sessionUuid: this.sessionUuid
            });
            this.$toast.success("Оценка успешно выставлена");
            this.updateStore({ entity: 'marksChanged', payload: true })
          })
          .finally(() => {
            this.setMarkRequestPending = false;
          })
    },
    async loadOnMount() {
      await this.getUserSession({
        planUuid: this.planUuid,
        taskUuid: this.taskUuid,
        respondentUuid: this.respondentUuid
      });
      await this.getScales({
        planUuid: this.planUuid,
        taskUuid: this.taskUuid,
        sessionUuid: this.sessionUuid
      });
    }
  },
}
</script>

<style lang="scss">
.custom-assessment {
  &__form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    padding-bottom: 30px;
    position: relative;
    margin: 0 -6px;
    margin-bottom: 30px;
    &::after{
      content: "";
      position: absolute;
      left: -30px;
      width: calc(100% + 48px);
      bottom: 0;
      border-bottom: 2px solid #DFE0EB;
    }
    &__btn {
      height: 48px;
      width: 100%;
    }

    .form {
      &__group {
        width: 33.33333333333%;
        height: 100%;
        padding: 6px;
        margin-bottom: 15px;
        .app-select {
          margin-top: auto;
        }

        @include max-xs {
          height: unset;
          width: 100%;
        }
      }
    }
  }
}

</style>