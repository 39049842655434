<template>
  <mt-loading-animation-linear v-if="!loaded"/>
  <div
      v-else
      class="respondents-table"
      :class="{'respondents-table_loading': respondentsLoading}"
      style="width: 100%"
  >
    <div class="respondents-table__header">
      <app-tooltip top>
        <template #activator>
          <mt-button
              icon
              color="light-gray"
              @click="exportUsers"
              :loading="exportRequestPending"
          >
            <export-icon/>
          </mt-button>
        </template>
        <template #default>Экспорт</template>
      </app-tooltip>

      <mt-filters
          style="margin-left: auto;"
          v-model:cols="cols"
          v-model:filters="computedFilters"
          v-model:sort="sort"
          v-model:search="search"
          :filtersSettings="filtersSettings"
          @change="updateExpPlanList"
          :filter-visibility="false"
      />
    </div>
    <div class="respondents-table__body">
      <div
          class="respondents-table__show-more"
          v-if="hasScroll"
          :class="{'respondents-table__show-more_available': showInfo}"
          @touchmove.prevent="scrollHorizontal()"
      >
        Прокрутите вправо, чтобы увидеть больше
      </div>
      <div
          class="respondents-table__wrapper"
          ref="table"
          v-dragscroll
          @scroll.prevent="scrollHorizontal"
      >
        <table>
          <thead>
          <tr>
            <th v-if="cols[0].visible">
              <div class="respondents-table__cell">
                Респондент
              </div>
            </th>
            <th v-if="cols[1].visible">Возраст</th>
            <template
                v-for="(test, index) in computedHeaderTestings"
                :key="index"
            >
              <th class="respondents-table__border-left">
                <div class="respondents-table__cell">
                  <mtSvg
                      class="respondents-table__header__icon mr-1"
                      color="#00788C"
                      :name="test.icon"
                      @click="showTaskDialog(test.uuid)"
                  />

                  {{ test.title }}
                  <button
                      v-if="test.hasOwnProperty('criterias') && test.criterias.length"
                      @click="toggleCriteriasDisplay(test)"
                  >
                    <div
                        :class="[
                          'respondents-table__header__toggler',
                          {
                            'respondents-table__header__toggler_active': test.uuid === activeTestColumn
                          }
                        ]"
                    />
                  </button>
                </div>
              </th>
              <th
                  v-show="test.uuid === activeTestColumn"
                  v-for="(criteria, index) in test.criterias"
                  :key="index"
              >
                {{ criteria.name }}
              </th>
            </template>
            <th class="respondents-table__border-left">Балл</th>
            <th class="respondents-table__border-left">Оценка</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(respondent, index) in respondents"
              :key="index"
          >
            <td v-if="cols[0].visible">
              <component
                  :is="respondentNameTag"
                  :to="{
                    name: 'RespondentCabinet',
                    params: {
                      respondentUuid: respondent.uuid
                    }
                  }"
                  class="respondents-table__cell_fullname"
              >
                {{ respondent.fullName }}
              </component>
            </td>
            <td v-if="cols[1].visible">{{ respondent.age }}</td>
            <template v-for="(task, index) in respondent.tests">
              <td
                  v-if="task.type === 'confirmation'"
                  class="respondents-table__border-left"
                  :key="index"
              >
                <mt-checkbox
                    class="ml-auto mr-auto"
                    disabled="disabled"
                    v-model="task.isChecked"
                />
              </td>
              <template v-else>
                <td
                    :key="index"
                    :class="[
                        'respondents-table__border-left',
                        'respondents-table__td',
                        {
                          'respondents-table__td_expert': task.type === 'expert'
                        }
                    ]"
                >
                  <div class="respondents-table__cell">
                    <template v-if="task.isCalculating">
                      Cчитывается...
                    </template>
                    <template v-else>
                      <component
                          :is="task.type === 'expert' && ['expert', 'inspector', 'respondent'].includes(authUserRoleSlug) && !assessmentPlan.isCompleted ? 'button' : 'div'"
                          :class="[
                            'respondents-table__cell__more',
                            {
                              'respondents-table__cell__more_failed': task.isFailed,
                              'respondents-table__cell__more_expert': task.type === 'expert' && ['expert', 'inspector', 'respondent'].includes(authUserRoleSlug) && !assessmentPlan.isCompleted
                            }
                          ]"
                          @click="task.type === 'expert' && !assessmentPlan.isCompleted  ? openTaskModal(task, respondent) : null"
                      >
                        <template v-if="!task.currentScore && !task.totalScore">
                          {{ task.totalScore }}
                          -
                        </template>
                        <template v-else>
                          {{ !task.isSet && !task.currentScore ? '-' : task.currentScore }} из {{
                            task.totalScore
                          }}
                        </template>
                      </component>
                      <button
                          class="ml-2"
                          v-if="task.reports.length"
                          @click="openDownloadReportModal(respondent.uuid, task)"
                      >
                        <mtSvg
                            name="download"
                            color="#00788C"
                        />
                      </button>
                      <img
                          class="respondents-table__cell__notification"
                          src="/img/icons/notification.svg"
                          v-if="task.hasNotifications"
                      />
                    </template>
                  </div>
                </td>
                <td
                    v-show="task.uuid === activeTestColumn"
                    v-for="(criteria, criteriaIndex) in task.criterias"
                    :key="criteriaIndex"
                >
                  <template v-if="criteria.currentScore === 0 && criteria.maxScore === 0">
                    -
                  </template>
                  <template v-else>
                    {{ !task.isSet ? '-' : criteria.currentScore }} из {{ criteria.maxScore }}
                  </template>
                </td>
              </template>
            </template>
            <td class="respondents-table__border-left">

              {{ respondent.totalScore }}
              <template v-if="respondent.isCalculating">
                (Cчитывается...)
              </template>
            </td>
            <td class="respondents-table__border-left">
              <template v-if="!respondent.isCalculating">
                {{ respondent.grade }}
              </template>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <mt-pagination
          class="respondents-table__pagination"
          v-if="respondentsMeta?.total"
          :total="respondentsMeta?.total"
          :page="respondentsMeta.current_page"
          :perPage="respondentsMeta.per_page"
          @change="loadRespondents"
      />
    </div>

  </div>
  <teleport to="body">
    <download-report-popover
        :visibility="isDownloadReportVisible"
        :reports="clipboardTask?.reports"
        @close="closeDownloadReportModal"
        @download="downloadReport"
    />
    <mt-dialog
        class="custom-assessment"
        size="md"
        v-model="taskDialog"
    >
      <mt-loading-animation-linear v-if="taskLoading"/>
      <template v-else>
        <h3 class="custom-assessment__expander__title">{{ chosenTask.name }}</h3>
        <p
            class="custom-assessment__expander__text"
            v-show="chosenTask.description"
        >
          {{ chosenTask.description }}
        </p>
        <template v-if="chosenTask.materials.length">
          <h2 class="custom-assessment__expander__file-title">Файлы</h2>
          <div class="card mt-2">
            <file-download-card :links="chosenTask.materials"/>
          </div>
        </template>
      </template>
    </mt-dialog>
  </teleport>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import MtCheckbox from "@/components/UI/mtCheckbox/index.vue";
import MtPagination from "@/components/UI/mtPagination/mtPagination.vue";
import MtFilters from "@/components/UI/mtFilters/mtFilters.vue";
import MtSvg from "@/components/UI/mtSvg/index.vue";
import api from "@/network";
import { dragscroll } from "vue-dragscroll";
import { taskSingleAdapter } from "@/adapters/assessmentPlans/tasks/tasks.adapter";
import MtDialog from "@/components/UI/mtDialog/mtDialog.vue";
import FileDownloadCard from "@/components/workspace/assessmentPlans/plans/fileDownloadCard/index.vue";
import MtLoadingAnimationLinear from "@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue";
import { roundNumber } from "@/services/Mathe";
import DownloadReportPopover from "@/components/workspace/testLibrary/results/DownloadReportPopover/index.vue";
import externalApi from "@/network/external";
import AppTooltip from "@/components/UI/AppTooltip/AppTooltip.vue";
import MtButton from "@/components/UI/mtButton/index.vue";
import ExportIcon from "@/components/icons/ExportIcon.vue";

export default {
  name: "ExpertReviewTable",
  components: {
    ExportIcon,
    MtButton,
    AppTooltip,
    DownloadReportPopover,
    MtLoadingAnimationLinear,
    FileDownloadCard,
    MtDialog,
    MtSvg,
    MtFilters,
    MtPagination,
    MtCheckbox
  },
  directives: {dragscroll},
  data: () => ({
    cols: [
      {
        "title": "ФИО",
        "stub": "full_name",
        "visible": true,
        "filter": "string",
        "filterable": false,
        "sortable": true,
        "showable": false
      },
      {
        "title": "Возраст",
        "stub": "birthday",
        "visible": true,
        "filter": "date",
        "filterable": false,
        "sortable": true,
        "showable": true
      }
    ],
    filtersSettings: {},
    chosenTask: {},
    sort: {},
    search: {},
    customFilters: [],

    clipboardTask: null,
    selectedResultsReport: [],
    taskDialog: false,
    taskLoading: true,
    showInfo: true,
    hasScroll: false,
    activeTestColumn: null,
    respondentsSortingBy: 'asc',
    respondentsLoading: false,
    isDownloadReportVisible: false,
    activeCustomAssessment: {},
    loaded: false,
    exportRequestPending: false
  }),
  computed: {
    ...mapGetters(['authUser', 'authUserRoleSlug']),
    ...mapState('assessmentPlans/plan/task', ['mark', 'marksChanged']),
    ...mapState('assessmentPlans/plan', ['assessmentPlan', 'respondents', 'respondentsMeta', 'filters']),


    computedHeaderTestings() {
      if (this.respondents.length === 0) return [];
      return this.respondents[0].tests;
    },

    respondentNameTag() {
      return this.authUser?.role?.slug === 'admin' ? 'router-link' : 'span';
    },

    computedFilters: {
      get() {
        return this.filters
      },
      set(newValue) {
        this.updateFilters(newValue)
      }
    }
  },
  methods: {
    ...mapActions('assessmentPlans/plan', ['fetchRespondents', 'completePlan', 'resumePlan']),
    ...mapActions('assessmentPlans/plan/task', ['fetchTask']),
    ...mapMutations('assessmentPlans/plan', ['updateFilters', 'updateStore']),

    async downloadReport({format, variants, titles}) {
      const payload = [
        {
          format,
          variants,
          selected_results_report: this.selectedResultsReport
        }
      ];
      const response = await api.post('export/results', payload);
      if (response.data?.url) {

        const reportLink = response.data.url;
        const fileNameInfo = response.data.FileNameInfo;
        const isGroup = variants.length > 1;

        if (isGroup) {
          window.open(reportLink, "_blank");
        } else {
          const response = await externalApi.get(`${ reportLink }`, {
            responseType: 'blob',
          });

          if (response.data.size) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;

            //let fileName = `results__${ new Date().toLocaleDateString() }-${ new Date().toLocaleTimeString().replace(/:/g, '_') }.${ type }`;
            let fileName = fileNameInfo['FullName'] + '_' + fileNameInfo['TestName'] + '_' +titles[0] + '_' +fileNameInfo['TestNumber'] + '.' + format;

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            this.$toast.error('Отчёт не найден');
          }
        }
      }
    },

    async showTaskDialog(taskUuid) {
      this.taskDialog = true;
      this.taskLoading = true;

      const {data} = await this.fetchTask({
        uuidPlan: this.$route.params.planUuid,
        uuid: taskUuid
      });

      this.chosenTask = taskSingleAdapter(data.data);
      this.taskLoading = false;
    },

    async loadRespondents(newValue) {
      this.respondentsMeta.current_page = newValue?.page;
      this.respondentsMeta.per_page = newValue?.perPage;

      let params = {
        page: this.respondentsMeta.current_page,
        per_page: this.respondentsMeta.per_page,
        settings: {...this.sort}
      };

      if (this.sort.order?.length) {
        this.respondentsSortingBy = this.sort.order
      }

      if (this.filters.length) {
        params = {
          ...params,
          settings: {
            ...params.settings,
            filters: this.filters,
          },
        };
      }

      if (this.filterByResult) {
        params = {
          ...params,
          result: this.filterByResult,
        };
      }

      if (!!this.search.value) {
        params.settings["value"] = this.search.value;

        if (!!this.search.search) {
          params.settings["search"] = this.search.search;
        }
      }

      if (!this.marksChanged) this.respondentsLoading = true;

      await this.fetchRespondents({
        uuid: this.$route.params.planUuid,
        params: params
      })
          .finally(() => {
            this.respondentsLoading = false;
            this.updateStore({entity: 'marksChanged', payload: false})
          });
    },

    scrollHorizontal() {
      if (this.$refs.table.scrollLeft > 0) {
        this.showInfo = false;
      }
    },

    exportUsers() {
      this.exportRequestPending = true;
      api.get(`export/plans/${this.$route.params.planUuid}`, {
        responseType: 'blob'
      })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;

            let fileName = `Таблица_ведомости__${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString().replace(/:/g, '_')}.xlsx`;

            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .finally(() => {
            this.exportRequestPending = false;
          })
    },

    toggleCriteriasDisplay(test) {
      if (this.activeTestColumn === test.uuid) {
        this.activeTestColumn = null;
        return;
      }
      this.activeTestColumn = test.uuid
    },

    updateExpPlanList() {
      this.respondentsMeta.current_page = 1;
      this.loadRespondents();
    },

    openDownloadReportModal(userUuid, task) {
      this.isDownloadReportVisible = true;

      this.selectedResultsReport = [{'uuid_session': task.sessionUuid, 'uuid_user': userUuid}];
      this.clipboardTask = task;
    },

    closeDownloadReportModal() {
      this.isDownloadReportVisible = false

      this.selectedResultsReport = [];
      this.clipboardTask = null;
    },

    openTaskModal(test, respondent) {
      this.activeCustomAssessment = test;

      const taskRoute = this.$route.name === 'ExpRespAssessmentPlanSingle' ? 'ExpertTaskSingle' : this.$route.name === 'AssessmentPlanSingleResults' ? 'PlanSingleResultsExpertTaskSingle' : ''

      this.$router.push({
        name: taskRoute,
        params: {
          planUuid: this.$route.params.planUuid,
          taskUuid: test.uuid,
          respondentUuid: respondent.uuid
        }
      })
    }

  },
  mounted() {
    this.loadRespondents()
        .then(() => {
          let table = this.$refs.table;
          if (table) {
            const verifyScroll = () => {
              this.hasScroll = table.scrollWidth - 20 > table.clientWidth;
            }
            verifyScroll();
            window.addEventListener('resize', verifyScroll);
          }
        })
        .finally(() => {
          this.loaded = true
        })
  },
  watch: {
    mark(val) {
      if (this.marksChanged) {
        this.loadRespondents()
      }
      let totalScore = val.scales ? val.scales.reduce((acc, scale) => {
        return acc + scale.value
      }, 0) : [];
      this.activeCustomAssessment.currentScore = roundNumber(totalScore);
    },
    filters() {
      this.loadRespondents()
    },
  }
}
</script>

<style lang="scss" scoped>
.respondents-table {
  background: #FFFFFF;
  border: 1px solid #DFE0EB;
  border-radius: 8px;
  position: relative;
  width: fit-content;
  max-width: 100%;
  transition: .3s opacity;

  &__body {
    position: relative;
  }

  &_loading {
    opacity: 0.7;
    pointer-events: none;
  }

  &__sort-icon {
    cursor: pointer;
    transition: transform .3s;

    &--active {
      transform: rotate(180deg);
    }
  }

  &__cell {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    max-width: 300px;

    &_fullname {
      white-space: nowrap;
    }

    &__notification {
      width: 16px;
      margin-left: 8px;
    }

    &__more {
      flex-shrink: 0;
      font-weight: 600;
      &_failed {
        color: #C24020;
      }
      &_expert {
        color: $black;
        text-decoration: underline;
      }
    }
  }

  a.respondents-table__cell_fullname {
    color: #000;
    text-decoration: underline;
    transition: color .3s;

    &:hover {
      color: #00788C;
    }
  }

  &__edit {
    width: 18px;
    height: 18px;
    margin-left: 8px;

    svg {
      fill: $green;
    }
  }

  &__show-more {
    position: absolute;
    width: 200px;
    height: 100%;
    background: linear-gradient(to right, transparent, #ffffff);
    top: 0;
    right: 0;
    padding: 0;
    text-align: right;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    pointer-events: none;

    &_available {
      opacity: 1;
      visibility: visible;
    }
  }

  &__header {
    height: 57px;
    display: flex;
    align-items: center;
    padding: 0 24px;

    &__toggler {
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 8px solid $green;
      margin-left: 10px;

      &_active {
        transform: scale(-1);
      }
    }
  }

  &__wrapper {
    overflow-x: auto;
  }

  th, td {
    padding: 12px 10px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #252733;
    vertical-align: top;
  }

  th {
    white-space: nowrap;
    background: #F0F1F7;
    box-shadow: inset 0px -1px 0px #E6E7E9;
  }

  tbody tr > * {
    border-bottom: 1px solid #DFE0EB;
    min-width: 130px;
  }

  table {
    width: 100%;
  }

  &__header {
    &__icon {
      cursor: pointer;

      svg {
        width: 20px;
        height: 18px;
      }

      &_pre {
        margin-right: 16px;
      }
    }
  }

  &__border-right {
    border-right: 2px solid #E6E7E9;
  }

  &__border-left {
    border-left: 2px solid #E6E7E9;
  }

  &__td {
    &_expert {
      /*padding: 0!important;
      .respondents-table__cell {
        padding: 12px 10px;
        background-color: $light-green;
        color: $green;
        cursor: pointer;
        &:hover {
          background-color: darken($light-green, 5%);
        }
        &:active {
          background-color: darken($light-green, 15%);
        }
      }*/
    }
  }

  &__pagination {
    justify-content: flex-end;
    min-height: 60px;
  }
}

.expert-plan {
  &__export-btn {
    background: #F0F1F7;
    border-radius: 8px;
    height: 33px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.custom-assessment {
  &__title {
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #252733;
    margin-bottom: 15px;
  }

  &__expander {
    margin-bottom: 20px;

    &__title {
      font-weight: 700;
      font-size: 19px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #252733;
      margin-bottom: 30px;
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #252733;
      white-space: pre-wrap;
    }

    &__file-title {
      font-weight: 600;
      margin-top: 30px;
    }

    .expander {
      &__item {
        &__header {
          padding-top: 0;
          max-width: calc(100% - 50px);
          @include max-xs {
            flex-wrap: nowrap;
            max-width: 100%;
          }
        }
      }
    }
  }
}

</style>