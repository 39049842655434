<template>
  <div class="tasks">
    <div class="tasks__header">
      <div class="tasks__header__title">Задания</div>
      <app-dropdown
        style="margin-left: auto"
        :items="taskTypes"
        ref="actionsDropdown"
      >
        <template #header>
          <mt-button class="tasks__header__add-btn">
            Добавить задание
            <arrow-down-icon
              style="transform: rotate(180deg); margin-left: 8px"
            />
          </mt-button>
        </template>
      </app-dropdown>
    </div>
    <info-block v-if="!tasks.length && !isLoading" />
    <plan-tasks-skeleton v-if="isLoading" />
    <draggable
      :component-data="{
        tag: 'div',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null,
      }"
      class="tasks__body"
      v-model="tasks"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
      item-key="order"
      handle=".js-drag"
      @change="checkChange"
      v-else
    >
      <template #item="{ element, index }">
        <div class="tasks__item">
          <div class="tasks__item__index">
            {{ index + 1 }}
          </div>
          <div class="tasks__item__content">
            <task-icon :type="element.type" class="tasks__item__icon" />

            <div class="tasks__item__name" :title="element.name">
              {{ element.name }}
            </div>

            <div class="tasks__item__buttons">
              <mt-button icon @click="openUpdateTaskDialog(element)">
                <pencil-icon />
              </mt-button>

              <mt-button class="js-drag tasks__item__buttons__drag-btn" icon>
                <drag-icon />
              </mt-button>

              <mt-button
                color="light-red"
                icon
                @click="activateDeleteDialog(element)"
              >
                <trash-icon />
              </mt-button>
            </div>
          </div>
        </div>
      </template>
    </draggable>
  </div>

  <teleport to="body">
    <mt-dialog size="md" v-model="createTaskDialogActivator">
      <task-form
        :submit="createItem"
        :tests-for-exclude="testsForExclude"
        button-text="Добавить"
        :type="typeForNewTask"
        :isPro="typeForNewTask == 'test_pro'"
      >
        <template #title>
          {{ createTitle }}
        </template>
      </task-form>
    </mt-dialog>
    <mt-dialog
      size="md"
      v-model="updateTaskDialogActivator"
      @close="itemForUpdate = null"
    >
      <task-form
        :submit="updateTask"
        :tests-for-exclude="testsForExclude"
        :predefined-data="itemForUpdate"
        :type="itemForUpdate?.type"
        :key="itemForUpdate?.uuid"
        button-text="Сохранить"
        :reset-after-submit="false"
      >
        <template #title>
          {{ updateTitle }}
        </template>
      </task-form>
    </mt-dialog>
    <confirmation-dialog
      v-model="deleteDialogActivator"
      :title="`Удалить задание ${deletingTask?.name}?`"
      :handler="deleteTask"
    />
  </teleport>
</template>

<script lang="ts">
import { mapGetters, mapState } from 'vuex'
import draggable from 'vuedraggable'
import mtPreloader from '@/components/UI/mtPreloader/mtPreloader.vue'
import mtButton from '@/components/UI/mtButton/index.vue'
import ConfirmationDialog from '@/components/composite/confirmationDialog/confirmationDialog.vue'
import TasksAdminApi from '@/api/tasks-admin.api'
import SettingsIcon from '@/components/icons/SettingsIcon.vue'
import TrashIcon from '@/components/icons/TrashIcon.vue'
import DragIcon from '@/components/icons/DragIcon.vue'
import InfoBlock from '@/components/workspace/InfoBlock.vue'
import MtDialog from '@/components/UI/mtDialog/mtDialog.vue'
import TaskForm from '@/components/workspace/assessmentPlans/plans/plan/step1/TaskForm.vue'
import PencilIcon from '@/components/icons/PencilIcon.vue'
import useToast from '@/components/UI/AppToast/useToast.js'
import TaskIcon from '@/components/workspace/assessmentPlans/plans/TaskIcon.vue'
import AppDropdown from '@/components/UI/AppDropdown/AppDropdown.vue'
import ArrowDownIcon from '@/components/icons/ArrowDownIcon.vue'
import TaskTypeEnum from '@/enums/task-type.enum.ts'
import PlansAdminApi from '@/api/plans-admin.api.ts'
import PlanTasksSkeleton from "@/components/workspace/assessmentPlans/plans/plan/step1/PlanTasksSkeleton.vue";
import taskTypes from '@/constants/task-types.constant'

export default {
  components: {
    PlanTasksSkeleton,
    ArrowDownIcon,
    AppDropdown,
    TaskIcon,
    PencilIcon,
    TaskForm,
    MtDialog,
    InfoBlock,
    DragIcon,
    TrashIcon,
    SettingsIcon,
    ConfirmationDialog,
    draggable,
    mtPreloader,
    mtButton,
  },
  data() {
    return {
      drag: false,
      deleteDialogActivator: false,
      deletingTask: null,

      updateTaskDialogActivator: false,
      itemForUpdate: null,

      createTaskDialogActivator: false,
      typeForNewTask: null,
    }
  },

  computed: {
    ...mapGetters('assessmentPlans/plan', ['planEditdataExd']),
    ...mapState('assessmentPlans/plan', ['isLoading']),
    createTitle() {
      return this.typeForNewTask === TaskTypeEnum.EXPERT
        ? 'Добавить экспертную оценку'
        : 'Добавить тест'
    },
    updateTitle() {
      return this.itemForUpdate?.type === TaskTypeEnum.EXPERT
        ? 'Редактировать экспертную оценку'
        : 'Редактировать тест'
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    tasks: {
      get() {
        return this.planEditdataExd.structure ?? []
      },
      set(value) {
        this.$store.commit('assessmentPlans/plan/updateTests', value)
      },
    },
    testsForExclude(): any[] {
      if (!this.planEditdataExd?.structure) return []

      return (
        this.planEditdataExd?.structure
          .filter((item) => {
            return (
              (item.type === 'test' || item.type === 'test_pro') &&
              this.itemForUpdate?.mainTest?.uuid !== item.mainTest?.uuid
            )
          })
          .map((item) => {
            return item.mainTest?.uuid
          }) ?? []
      )
    },

    taskTypes() {
      return taskTypes.map((t) => {
        return {
          label: t.title,
          onClick: () => this.openCreateTaskDialog(t.value)
        }
      })
      // return [
      //   {
      //     label: 'Экспертная оценка',
      //     onClick: () => this.openCreateTaskDialog(TaskTypeEnum.EXPERT),
      //   },
      //   {
      //     label: 'Тест',
      //     onClick: () => this.openCreateTaskDialog(TaskTypeEnum.TEST),
      //   },
      // ]
    },
  },

  methods: {
    validate() {
      const { toast } = useToast()
      if (!this.tasks.length) {
        toast.error('Для продолжения работы добавьте задания в план оценки')
        return false
      }

      return true
    },
    checkChange(payload) {
      if (!payload.moved) return

      const oldIndex = payload.moved.oldIndex
      const newIndex = payload.moved.newIndex

      let newOrder

      if (newIndex === oldIndex) return
      if (newIndex > oldIndex) {
        const prevItem = this.planEditdataExd.structure[newIndex - 1]
        newOrder = prevItem?.order
      }
      if (newIndex < oldIndex) {
        const nextItem = this.planEditdataExd.structure[newIndex + 1]
        newOrder = nextItem?.order
      }

      const taskUuid = payload.moved.element.uuid

      TasksAdminApi.updateOrder(
        this.$route.params.planUuid,
        taskUuid,
        newOrder ?? 1,
      ).then((response) => {
        this.$emit('fixTestOrder')
      })
    },
    async createItem(payload) {
      await TasksAdminApi.create(this.$route.params.planUuid, payload).then(
        async (response) => {
          await PlansAdminApi.get(this.$route.params.planUuid)

          TasksAdminApi.get(this.$route.params.planUuid, response.data.uuid)
          this.createTaskDialogActivator = false
          this.tasks.push(response.data)
          this.$store.commit('assessmentPlans/plan/updateTests', this.tasks)
        },
      )
    },
    openCreateTaskDialog(type: string) {
      this.createTaskDialogActivator = true
      this.typeForNewTask = type
    },

    async updateTask(payload) {
      console.log(payload)

      return await TasksAdminApi.update(
        this.$route.params.planUuid as string,
        this.itemForUpdate.uuid,
        payload,
      )
        .then((response) => {
          this.itemForUpdate = response.data
          const index = this.planEditdataExd.structure.findIndex(
            (item) => item.uuid === this.itemForUpdate.uuid,
          )
          this.planEditdataExd.structure[index] = response.data
          console.log(this.planEditdataExd.structure)
          this.$store.commit(
            'assessmentPlans/plan/updateTests',
            this.planEditdataExd.structure,
          )

          this.updateTaskDialogActivator = false
        })
        .finally(() => {
          this.updateTaskDialogActivator = false
        })
    },

    openUpdateTaskDialog(item) {
      this.itemForUpdate = item
      this.updateTaskDialogActivator = true
    },

    activateDeleteDialog(payload) {
      this.deleteDialogActivator = true
      this.deletingTask = payload
    },

    async deleteTask() {
      return await TasksAdminApi.delete({
        planUuid: this.$route.params.planUuid as string,
        testUuid: this.deletingTask!.uuid,
      })
        .then((response) => {
          const index = this.planEditdataExd.structure.findIndex(
            (item) => item.uuid === this.deletingTask.uuid,
          )
          this.planEditdataExd.structure.splice(index, 1)
        })
        .finally(() => {
          this.deleteDialogActivator = false
        })
    },
  },
}
</script>

<style lang="scss">
.tasks {
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  width: 100%;
  &__header {
    padding: 15px;
    display: flex;
    align-items: center;
    &__title {
      font-weight: 500;
      color: #7c869d;
    }
    &__add-btn {
      height: 33px;
      width: 200px;
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #eee;
    &:last-child {
      border-bottom: unset;
    }
    &__content {
      width: calc(100% - 32px);
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    &__name {
      margin-left: 10px;
      flex-grow: 1;
      @include textEllipsis;
    }
    &__index {
      font-size: 14px;
      color: #c7c7c7;
      width: 24px;
      text-align: left;
    }
    &__buttons {
      display: flex;
      margin-left: auto;
      gap: 10px;

      &__drag-btn {
        cursor: move;
      }
    }
  }
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}
</style>
